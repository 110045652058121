<template>
  <div class="view-series">
    <div class="container-fluid py-5">
      <div class="container pt-5">
        <div class="row">
          <div
            v-for="(season, index) in orderedSeasons"
            :key="index"
            class="col-lg-4 col-md-6 py-2"
          >
            <serie-button :season="season" :useLandscape="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash/get'
import SerieButton from '../components/SerieButton'

export default {
  components: { SerieButton },
  computed: {
    ...mapState('seasons', ['seasons']),
    orderedSeasons () {
      if (get(this.$user, 'user.teams')) {
        const teamsIds = this.$user.user.teams.map(h => h.seasontmp.id)
        const nonTeams = this.seasons.filter(season => !teamsIds.includes(season.id))
        const teams = this.$user.user.teams
          .map(item => this.seasons.find(season => season.id === item.seasontmp.id))
          .filter(item => item)
        return [...teams, ...nonTeams]
      }
      return this.seasons
    }
  }
}
</script>
